import React, { useEffect, useState } from 'react';
import { Layout } from 'layouts';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import Constants from '../../static/Constants';
import Helmet from 'react-helmet';
import StepperForm from '../components/DipsForm/StepperForm';
import MuiAlert from '@material-ui/lab/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_CAPTCHA_V2_KEY } from '../shared/constants';
import {
  Backdrop,
  Button,
  CircularProgress,
  createTheme,
  makeStyles,
  MuiThemeProvider,
  Snackbar,
} from '@material-ui/core';
import { create } from 'apisauce';
import EditIOPPopup from '../components/DipsForm/Extras/EditIOPPopup';
import { Container } from '@material-ui/core';
import VerticalFixedNav from '../styles/verticalFixedNav';

const globalConfig = require('../../static/config.json');

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3b8f',
    },
    secondary: {
      main: 'rgb(0, 174, 200)',
    },
  },
});

const useStyles = makeStyles(theme => ({
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100vw',
    backgroundColor: '#eee',
    padding: '15px',
    height: '500px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  wwhTitle: {
    fontSize: '36px',
    color: '#151515',
    marginTop: '30px',
    marginBottom: '30px',
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '50px',
  },
  wwhSubtitle: {
    fontSize: '24px',
    color: '#656363',
    fontWeight: 700,
    marginBottom: '15px',
  },
  wwhConentContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const IOPCampaign = ({ ...props }) => {
  const { VerticalFixedNavStyles } = VerticalFixedNav;
  const classes = useStyles();
  const { t } = useTranslation();
  const { pageContext, location } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;
  if (!data) {
    return;
  }
  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);
  const { pageHeader, headerLogo, headerLogoScroll, screenName } =
    (data || {}).node || {};
  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];

  const helmetLink = `${Constants.appUrl}/wwh-conference`;
  // const baseURL = Constants.apiUrl;
  // const isComingSoon = baseURL == 'https://dipsapi.unido.org' ? true : false;
  const apiSauceInstance = create({ baseURL: Constants.apiUrl });

  const [snackbarState, setSnackbarState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [externalCampaignMeta, setExternalCampaignMeta] = useState({
    projectID: null,
    campaignID: null,
    sourceEntityID: null,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isReadOnlyMode, setIsReadOnlyMode] = useState(null);
  const [isNewRecord, setIsNewRecord] = useState(false);
  const [isCaptchaPassed, setIsCaptchaPassed] = useState('');
  const [displayForm, setDisplayForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);

  const handleSnackbar = (mode, title) => {
    setSnackbarState({
      isOpen: true,
      mode,
      title: title,
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState(false);
  };

  const getIOPMetaInfo = async (sourceEntityId = 0) => {
    setIsLoading(true);
    try {
      const { data, status } = await apiSauceInstance.post(
        `${Constants.getExternalCampaignDetails}?CampaignID=${globalConfig.wwhConferenceId}&SourceEntityID=${sourceEntityId}`
      );

      if (status === 200 || status === 201) {
        setExternalCampaignMeta({
          projectID: data?.projectID,
          campaignID: data?.campaignID,
          sourceEntityID: data?.sourceEntityID,
          currentUserID: data?.currentUserID,
          currentRoleID: data?.currentRoleID,
          iopProfilerUserID: data?.iopProfilerUserID,
          currentProjectStatusID: data?.currentProjectStatusID,
          projectCode: data?.projectCode,
        });

        if (sourceEntityId == 0) {
          setIsNewRecord(true);
        }

        setDisplayForm(true);
        setIsLoading(false);
        return true;
      } else {
        //error do something
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  };
  // changes added
  const getExistingExternalCampaignMeta = (
    sourceEntityId,
    projectId,
    isReadOnly
  ) => {
    setExternalCampaignMeta({
      // projectID: 8074,
      // sourceEntityID: 14917,
      projectID: projectId,
      sourceEntityID: sourceEntityId,
      campaignID: globalConfig.wwhConferenceId,
      currentUserID: 0,
      currentRoleID: 0,
      iopProfilerUserID: 0,
    });
    setIsReadOnlyMode(isReadOnly == null || undefined ? false : isReadOnly);

    setIsEditMode(true);
    setDisplayForm(true);
  };
  // changes added

  const onCaptchaChange = value => {
    if (value) {
      setIsCaptchaPassed(true);
    } else {
      setIsCaptchaPassed(false);
    }
  };

  const sendOTPToSERepresentative = async email => {
    setIsLoading(true);
    try {
      const { data, status } = await apiSauceInstance.post(
        `${Constants.sendSERepOTP}`,
        {
          email: email,
          campaignId: Number(globalConfig?.wwhConferenceId),
        }
      );
      setIsLoading(false);

      handleSnackbar(data?.isSuccess ? 'success' : 'error', data?.status);

      if (status === 200 || status === 201) {
        return true;
      } else {
        // handleSnackbar('error', data?.status);
        return false;
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const verifyOTPToSERepresentative = async (email, otp) => {
    setIsLoading(true);
    try {
      const { data, status } = await apiSauceInstance.post(
        `${Constants.verifySERepOTP}`,
        {
          email: email,
          otp: otp?.trim(),
        }
      );
      setIsLoading(false);

      handleSnackbar(data?.isSuccess ? 'success' : 'error', data?.status);

      if (status === 200 || status === 201) {
        // handleSnackbar('success', 'OTP verified successfully!');
        return true;
      } else {
        // handleSnackbar('error', 'Error while verifying OTP!');
        return false;
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSEListFromEmail = async email => {
    setIsLoading(true);
    try {
      const { data, status } = await apiSauceInstance.post(
        `${Constants.getSEList}`,
        {
          email: email,
          campaignId: Number(globalConfig?.wwhConferenceId),
        }
      );
      setIsLoading(false);

      if (status === 200 || status === 201) {
        return data;
      } else {
        return [];
        //error do something
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getProjectFromEmail = async sourceEntityId => {
    setIsLoading(true);
    try {
      const { data, status } = await apiSauceInstance.post(
        `${Constants.getIOPProjectList}`,
        {
          sourceEntityId: sourceEntityId,
          campaignId: Number(globalConfig?.wwhConferenceId),
        }
      );
      setIsLoading(false);

      if (status === 200 || status === 201) {
        return data;
      } else {
        return [];
        //error do something
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const baseURL = Constants.apiUrl;

  return (
    <Layout
      programLink={programLink}
      title={t('IOPCampaign')}
      content={[]}
      headerLinks={headerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName={'bg-analytics.png'}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'IOPCampaign' }}
      screenName={screenName}
      isExternalForm={true}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Investment Opportunity Profile Campaign form</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <div className="container">
          {displayForm ? (
            <StepperForm
              backdropCSS={classes.backdrop}
              externalCampaignMeta={externalCampaignMeta}
              isEditMode={isEditMode}
              setIsNewRecord={setIsNewRecord}
              isNewRecord={isNewRecord}
              isReadOnlyMode={isReadOnlyMode}
            />
          ) : (
            <>
              <Container css={VerticalFixedNavStyles}>
                <div
                  className="wwh-section"
                  style={{
                    marginTop: '15px',
                  }}
                >
                  <div
                    className="wwh-details"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <ReCAPTCHA
                        sitekey={GOOGLE_CAPTCHA_V2_KEY}
                        // size="compact"
                        onChange={onCaptchaChange}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          disabled={!isCaptchaPassed}
                          // disabled
                          variant="contained"
                          size="large"
                          className="btn-primary"
                          style={{ marginTop: '10px' }}
                          onClick={() => {
                            setOpenEditForm(true);
                            // getExistingExternalCampaignMeta(15312, 8456);
                            // getExistingExternalCampaignMeta(15056, 8206);
                          }}
                        >
                          Edit existing
                        </Button>
                        <Button
                          disabled={!isCaptchaPassed}
                          variant="contained"
                          size="large"
                          className="btn-primary"
                          style={{ marginTop: '10px' }}
                          onClick={() => {
                            getIOPMetaInfo();
                          }}
                        >
                          Create
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.wwhConentContainer}>
                  <div
                    className={classes.wwhSubtitle}
                    style={{ fontSize: '20px' }}
                  >
                    Submission of Proposals
                  </div>
                  <div>
                    Proposals will be received on a rolling basis. To be
                    considered for the investment forum, ensure your proposal is
                    submitted by&nbsp;
                    <span
                      style={{
                        fontStyle: 'italic',
                        backgroundColor: 'yellow',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}
                    >
                      August 15th.
                    </span>
                  </div>

                  <center>
                    <h5
                      style={{
                        fontStyle: 'italic',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                    >
                      (Please use the &nbsp;
                      <a
                        className="links-blanks"
                        href={`${baseURL}/resources/Portal/files/IOP-Form-Blank-WWHC.pdf`}
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        IOP form &nbsp;
                      </a>
                      as a reference. Projects must me submitted
                      electronically.)
                    </h5>
                  </center>
                </div>
                <div className={classes.wwhConentContainer}>
                  <div
                    className={classes.wwhSubtitle}
                    style={{ fontSize: '20px' }}
                  >
                    Selection Process
                  </div>
                  <div>
                    The selection process is managed by the UNIDO. To qualify
                    for selection, complete the online application form by the
                    aforementioned deadline. Proposals will be evaluated based
                    on their relevance to the conference themes, and the
                    diversity of the panelists involved. All applicants will be
                    informed of the outcomes in late September.
                  </div>
                </div>
                <div className={classes.wwhConentContainer}>
                  <div
                    className={classes.wwhSubtitle}
                    style={{ fontSize: '20px' }}
                  >
                    Contact
                  </div>
                  <div>
                    For more information, please contact:&nbsp;
                    <a
                      className="links-blanks"
                      href="mailto:investmentportal@unido.org"
                    >
                      investmentportal@unido.org
                    </a>
                  </div>
                </div>
              </Container>
            </>
          )}

          <div>
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="secondary" />
            </Backdrop>
          </div>
          {openEditForm && (
            <EditIOPPopup
              setOpen={setOpenEditForm}
              open={openEditForm}
              sendOTP={sendOTPToSERepresentative}
              verifyOTP={verifyOTPToSERepresentative}
              getSEList={getSEListFromEmail}
              getProjectList={getProjectFromEmail}
              getExistingExternalCampaignMeta={getExistingExternalCampaignMeta}
              getIOPMetaInfo={getIOPMetaInfo}
              backdropCSS={classes.backdrop}
              loader={isLoading}
              handleSnackbar={handleSnackbar}
            />
          )}
        </div>

        {snackbarState?.isOpen && (
          <Snackbar
            open={snackbarState?.isOpen || false}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarState.mode}
              style={{ fontSize: '14px' }}
            >
              {snackbarState.title}
            </Alert>
          </Snackbar>
        )}
      </MuiThemeProvider>
    </Layout>
  );
};

export default IOPCampaign;
